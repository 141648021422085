/*! purgecss start ignore */

.main-aside-filtration-toggle {

  .js-map-filtration-close {
    display: none;
  }

  &.--open {

    .js-map-filtration-close {
      display: inline-flex;
    }

    .js-map-filtration-open {
      display: none;
    }
  }
}

/*! purgecss end ignore */
