@use "settings/s-colors.scss" as colors;

.main-filter-toggle__body {
  height: 0;
  opacity: 0;
  transition: opacity 0.3s ease;
  transition-property: opacity, height, transform;
  pointer-events: none;
  transform: scaleY(0.5);
  transform-origin: top center;

  &.--active {
    height: auto;
    opacity: 1;
    pointer-events: all;
    transform: scaleY(1);
  }
}

.main-filter-toggle__content {
  padding-bottom: 16px;
}

.main-filter-toggle__icon {
  transition: transform 0.3s ease;
  transform: scaleY(1);
}

.main-filter-toggle__title {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  font-weight: bold;
  color: colors.$primary;

  &.--active {

    .main-filter-toggle__icon {
      transform: scaleY(-1);
    }
  }
}

.main-filter-toggle__header {
  display: flex;
  position: relative;

  @include media-breakpoint-down(md) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }
}

.main-filter-toggle {
  display: flex;
  flex-direction: column;
}

.main-filter-toggle__button {

  @include media-breakpoint-up(md) {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}
