@use "./../../../scss/settings/s-colors.scss" as colors;

.i-subcategory {
  color: colors.$black;
  font-size: 18px;
  display: flex;
  align-items: center;
  column-gap: 8px;

  @include media-breakpoint-only(xl) {
    font-size: 12px;
  }

  &::after {
    background-color: colors.$conversion-600;
    width: 10px;
    height: 10px;
    content: "";
    display: block;
    border-radius: 3px;
    transform: translateY(1px) rotate(45deg);
  }

  &:hover {
    color: colors.$black;
  }
}
