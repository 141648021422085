@use "./../../scss/settings/s-colors.scss" as colors;

/* purgecss start ignore */

.main-header__container {
  display: flex;
  align-items: center;
  min-height: 56px;
  position: relative;
}

.main-header {
  background-color: colors.$primary;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  z-index: 150;
  position: fixed;
  transition: transform 0.3s ease;
  padding: 8px 0;

  &.headroom--pinned {
    transform: translateX(-50%) translateY(0%);
  }

  &.headroom--unpinned {
    transform: translateX(-50%) translateY(-100%);
  }

  &.--dark {
    padding: 16px 0;
    background: transparent linear-gradient(180deg, #062841 0%, #06284100 100%) 0% 0% no-repeat padding-box;

    // basicaly status is open

    &.headroom--frozen {
      background: colors.$black;
    }

    .main-header__container {
      align-items: flex-start;
    }
  }
}

.main-header__content {
  display: flex;
  flex-shrink: 0;
  margin-left: auto;
  column-gap: 24px;
  align-items: center;

  @include media-breakpoint-down(md) {
    display: none;
  }
}

.main-header__buttons-group {
  display: flex;
  gap: 8px;
}

.main-header__notifications-group {
  display: flex;
  flex-wrap: nowrap;
  flex-shrink: 0;
  flex-grow: 1;
  gap: 8px;
}

.main-header__notification-icon {
  display: flex;
  height: 24px;
  width: 24px;
  margin-inline: auto;
  justify-content: center;
  align-items: center;

  path,
  rect,
  line {
    stroke: colors.$white;
  }
}

.main-header__search-button {
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  margin-left: auto;

  path {
    fill: colors.$white;
  }
}

.main-header__buttons-group-mobile {
  display: none;

  @include media-breakpoint-down(md) {
    display: block;
    margin-left: auto;
  }
}

.main-header__logo {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.main-header__breadcrumbs {

  @include media-breakpoint-down(md) {
    display: none;
  }
}

/* purgecss end ignore */
