@use "./../../scss/settings/s-colors.scss" as colors;

.base-header {
  position: relative;
  text-align: center;
  margin: 0 auto;
  max-width: 840px;
  margin-bottom: 48px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  &.--left {
    text-align: left;
    margin-right: 0;
    margin-left: 0;
  }

  &.--underline {
    max-width: 100%;
  }

  &.--m-bottom-0 {
    margin-bottom: 0;
  }

  &.--size-md {
    margin-bottom: 24px;

    .base-header__heading {

      @extend %base-h2;
    }
  }
}

.base-header__paragraph {
  margin-bottom: 0;

  span {
    color: colors.$primary;
    font-weight: 700;
  }
}

.base-header__heading-wrapper {
  display: flex;
  align-items: center;
  gap: 16px;
  padding-bottom: 8px;
  margin-bottom: 24px;
  border-bottom: 1px solid colors.$gray;
  font-size: 26px;

  .base-header__heading {
    font-weight: 700;
    font-size: inherit;
  }
}

.base-header__number {
  font-size: inherit;
}
