@use "../settings/s-general.scss" as general;

@use "sass:map";

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1500px,
  fullhd: 1920px,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 864px,
  xl: 1140px,
  xxl: 1358px,
);

$grid-gutter-width: general.$columnGap;

$spacer: general.$spacing * 3;
$spacers: ();
$spacers: map.deep-merge(
  (
    0: 0,
    1: (
      $spacer * 1,
    ),
    2: (
      $spacer * 2,
    ),
    3: (
      $spacer * 3,
    ),
    4: (
      $spacer * 4,
    ),
    5: (
      $spacer * 5,
    ),
    6: (
      $spacer * 6,
    ),
    7: (
      $spacer * 7,
    ),
    8: (
      $spacer * 8,
    ),
    9: (
      $spacer * 9,
    ),
    10: (
      $spacer * 10,
    ),
    11: (
      $spacer * 11,
    ),
    12: (
      $spacer * 12,
    ),
  ),
  $spacers
);
