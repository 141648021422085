@use "./../../../scss/settings/s-colors.scss" as colors;

/* purgecss start ignore */

.regions-map {
  max-width: 100%;
}

.regions-map__region {
  transition: fill 0.3s ease;
  cursor: pointer;
  fill: transparent;

  &.--active {
    fill: colors.$conversion;
  }

  &:hover {
    fill: colors.$conversion;
  }
}

.regions-map__overlay {
  pointer-events: none;
}

/* purgecss end ignore */
