@use "./../../../scss/settings/s-colors.scss" as colors;

.i-re-agent {
  display: flex;
  align-items: center;
  box-shadow: 0 4px 14px #00000029;
  border-radius: 10px;
  gap: 40px;

  @include media-breakpoint-down(xl) {
    gap: 16px;
    flex-wrap: wrap;
    padding: 16px;
    justify-content: space-between;
  }
}

.i-re-agent__agent-image {
  width: 208px;
  height: 208px;
  flex-shrink: 0;

  @include media-breakpoint-down(sm) {
    width: 132px;
    height: 132px;
  }
}

.i-re-agent__re-agency-logo {
  width: 172px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-grow: 1;

  @include media-breakpoint-down(xxl) {
    width: 120px;
  }

  @include media-breakpoint-down(xl) {
    width: 172px;
  }

  @include media-breakpoint-down(sm) {
    width: 120px;
  }
}

.i-re-agent__content {
  display: flex;
  flex-direction: column;
}

.i-re-agent__chart-button {
  padding-right: 32px;
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  @include media-breakpoint-down(xl) {
    width: 100%;
    padding-right: 0;
  }

  @include media-breakpoint-down(md) {
    flex-wrap: wrap;
    gap: 32px;
  }
}

.i-re-agent__statistical {

  .i-statistical__value {
    margin-bottom: 0;
  }

  @include media-breakpoint-down(md) {
    display: none;
  }
}
