@use "./../../scss/settings/s-colors.scss" as colors;

/* purgecss start ignore */

.base-modal {
  display: none;
  visibility: hidden;
  pointer-events: none;
  opacity: 0;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000b0;
  z-index: 1001;
  padding: 40px 10px;

  &.--js-modal-open {
    display: flex;
    visibility: visible;
    pointer-events: all;
    animation: open-modal 300ms ease-in-out;
    animation-fill-mode: forwards;
  }

  &.--js-modal-closing {
    animation: close-modal 300ms ease-in-out;
    animation-fill-mode: forwards;
  }
}

.base-modal__box {
  position: relative;
  background-color: #fff;
  display: none;
  visibility: hidden;
  pointer-events: none;
  opacity: 0;
  max-width: 100%;
  border-radius: 8px;
  width: 688px;
  max-height: 100%;
  overflow: auto;

  &.--sm {
    width: 560px;
  }

  &.--md {
    width: 688px;
  }

  &.--lg {
    width: 850px;
  }

  &.--js-modal-open {
    display: flex;
    visibility: visible;
    pointer-events: all;
    opacity: 1;
    animation: open-modal-box 300ms ease-in-out;
    animation-fill-mode: forwards;
  }

  &.--js-modal-closing {
    animation: close-modal-box 300ms ease-in-out;
    animation-fill-mode: forwards;
  }
}

.base-modal__close-out-image {
  transition: transform 200ms ease-in-out;
}

.base-modal__close-out {
  display: flex;
  align-items: center;
  position: fixed;
  top: 32px;
  right: 48px;
  font-size: 21px;
  color: #fff;
  gap: 14px;
  cursor: pointer;

  &:hover .base-modal__close-out-image {
    transform: rotate(90deg);
  }
}

.base-modal__close-in {
  position: absolute;
  top: 20px;
  right: 16px;
  font-size: 20px;
  transition: transform 200ms ease-in-out;
  cursor: pointer;

  &:hover {
    transform: rotate(90deg);
  }
}

.base-modal__content {
  width: 100%;
}

@keyframes open-modal {

  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes open-modal-box {

  0% {
    opacity: 0;
    transform: translateY(40px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes close-modal {

  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes close-modal-box {

  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(40px);
  }
}

/* purgecss end ignore */
