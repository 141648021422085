@use "./../../scss/settings/s-colors.scss" as colors;

.breadcrumbs__item {
  display: flex;
  align-items: center;
  gap: 0 16px;
  flex-shrink: 0;

  &:first-of-type {

    .icon {
      display: none;
    }
  }

  a,
  span {
    color: colors.$gray-300;
    font-weight: 400;
    flex-shrink: 0;
  }
}

.breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0 16px;

  &.--color-white {

    .breadcrumbs__item {

      a,
      span {
        color: colors.$white;
      }
    }
  }
}
