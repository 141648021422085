@use "./../../../scss/settings/s-colors.scss" as colors;

@use "./../../../scss/settings/s-general.scss" as general;

.i-estate-detail-intro__img {
  display: block;
  border-radius: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;

  * {
    border-radius: 0;
  }

  .img img {
    border-radius: 0;
  }
}
