@use "./../../../scss/settings/s-colors.scss" as colors;

/* purgecss start ignore */

.mega-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: colors.$black;
  color: colors.$gray-100;
  width: 100%;
  z-index: 190;
  opacity: 0;
  pointer-events: none;
  transform: translateY(10px);
  transition: opacity 0.3s ease;
  transition-property: opacity, transform;

  &.--open {
    opacity: 1;
    pointer-events: all;
    transform: translateY(0);

    @include media-breakpoint-down(md) {
      height: 100vh;
      overflow-y: scroll;
    }
  }
}

.mega-menu__content {
  padding: 56px 0;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 16px;

  @include media-breakpoint-down(md) {
    row-gap: 72px;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 56px;
    padding-bottom: 56px;
  }
}

.mega-menu__region {
  grid-column: span 6 / auto;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 24px;

  @include media-breakpoint-down(md) {
    grid-column: span 12 / auto;
  }
}

.mega-menu__navigation {
  grid-column: span 3 / auto;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 24px;

  @include media-breakpoint-down(md) {
    grid-column: span 12 / auto;
  }
}

.mega-menu__heading {
  font-size: 26px;
}

.mega-menu__list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.mega-menu__region-map {
  max-width: 525px;

  @include media-breakpoint-down(md) {
    display: none;
  }
}

.mega-menu__region-select {
  display: none;

  @include media-breakpoint-down(md) {
    display: block;
  }
}

/* purgecss end ignore */
