@use "./../../../scss/settings/s-colors.scss" as colors;

.i-filter-table-form__option {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 17px 40px;
  border-bottom: 1px solid colors.$primary-150;
}

.i-filter-table-form__option-label {
  flex-grow: 1;
  font-size: 18px;
}

.i-filter-table-form__option-extra {
  padding: 0 30px;

  span {
    display: block;
    font-size: 14px;
    line-height: 1.2;
  }
}

.i-filter-table-form__option-extra .dropdown__wrapper {
  line-height: 1;
}

.i-filter-table-form__fieldset {

  &.--modal {
    background-color: colors.$gray-100;
    margin: 10px -25px;
  }
}

.i-filter-table-form__footer-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
