@use "./../../scss/settings/s-colors.scss" as colors;

@use "./../../scss/settings/s-general.scss" as general;

/* purgecss start ignore */

.dropdown__wrapper {

  .choices__placeholder {
    opacity: 1;
  }

  .choices {
    border: 0;
    margin-bottom: 0;

    &::after {
      display: none;
    }

    &.is-open {

      .choices__inner {

        &::before {
          transform: translateY(-50%) rotate(-90deg);
        }
      }
    }
  }

  .choices__inner { /* stylelint-disable-line no-descending-specificity */
    border: 0;
    min-height: 24px;
    padding-right: 30px;

    &::before { /* stylelint-disable-line no-descending-specificity */
      content: "";
      position: absolute;
      top: 50%;
      right: 16px;
      width: 14px;
      height: 21px;
      transform: translateY(-50%) rotate(90deg);
      background-image: url("./images/svg/arrow-small-right.svg");
      background-size: contain;
      background-repeat: no-repeat;
      transition: transform 0.2s;
      transform-origin: center;
    }

    .choices__list {
      padding: 0;
    }
  }

  .choices__item {
    font-size: 16px;
    line-height: 24px;
    padding: 0 general.$spacing * 0.5;
  }

  .choices__inner + .choices__list {
    outline: none;
    border: none;
    padding: 0 !important;
    position: absolute;
    overflow: visible;
    border-radius: general.$border-base;

    .choices__list {
      overflow: auto;

      &::-webkit-scrollbar {
        width: 8px;
        border-radius: 8px;
      }

      &::-webkit-scrollbar-thumb {
        background: colors.$gray-200;
        width: 8px;
        height: 12px;
        min-height: 12px;
        max-height: 12px !important;
        border-radius: 8px;
      }
    }

    .choices__item {
      padding: general.$spacing * 0.5;

      &.is-selected {
        opacity: 0;
        height: 0;
        padding: 0 !important;
      }

      &::after {
        display: none;
      }
    }
  }

  &.--small {

    .choices {
      display: inline-flex;
    }

    .choices__inner { /* stylelint-disable-line no-descending-specificity */
      background-color: transparent;
      padding-top: general.$spacing * 0.5;
      padding-bottom: general.$spacing * 0.5;
      padding-left: 0;

      &::before { /* stylelint-disable-line no-descending-specificity */
        right: 4px;
      }

      .choices__item {
        color: colors.$primary;
        font-weight: bold;
        text-align: left;
        padding: 0 general.$spacing * 0.5;
      }
    }

    .choices__list--dropdown {
      outline: none;
      border: none;
      padding: general.$spacing * 0.5 0 !important;
      padding-bottom: general.$spacing * 1.5 !important;
      border-radius: general.$border-base;
      width: max-content;

      .choices__item {
        line-height: 24px;
        font-size: 16px;
        padding: general.$spacing * 0.5 19px;

        &:hover,
        &:active,
        &:focus,
        &.is-highlighted {
          background-color: colors.$gray-100;
        }
      }
    }
  }

  &.--default {

    .choices {
      box-shadow: inset 0 0 0 1px $gray-200;
      border-radius: general.$border-base;
      background-color: colors.$white;

      &.is-open {
        border-radius: general.$border-base general.$border-base 0 0;
      }

      &.is-flipped {
        border-radius: 0 0 general.$border-base general.$border-base;

        & > .choices__list {

          &::before {
            border-radius: general.$border-base general.$border-base 0 0;
          }

          &::after {
            top: 100%;
          }
        }
      }
    }

    .choices__inner { /* stylelint-disable-line no-descending-specificity */
      background-color: transparent;
      padding: general.$spacing * 1.5 17px;
      padding-right: 55px;

      &::before { /* stylelint-disable-line no-descending-specificity */
        right: 20px;
      }

      .choices__item { /* stylelint-disable-line no-descending-specificity */
        text-align: left;
        padding: 0;
        line-height: 24px;
        font-size: 18px;
      }
    }

    .choices__inner + .choices__list { /* stylelint-disable-line no-descending-specificity */
      padding: general.$spacing * 0.5 0 !important;
      padding-bottom: general.$spacing * 1.5 !important;

      &::before { /* stylelint-disable-line no-descending-specificity */
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-shadow: inset 0 0 0 1px $gray-200;
        background-color: colors.$white;
        border-radius: 0 0 general.$border-base general.$border-base;
      }

      &::after { /* stylelint-disable-line no-descending-specificity */
        content: "";
        position: absolute;
        top: 0;
        left: 1px;
        width: calc(100% - 2px);
        height: 2px;
        background-color: colors.$white;
        transform: translateY(-50%);
      }

      .choices__item { /* stylelint-disable-line no-descending-specificity */
        padding: general.$spacing * 0.5 17px;
        line-height: 24px;
        font-size: 18px;

        &:hover,
        &:active,
        &:focus,
        &.is-highlighted {
          background-color: colors.$gray-100;
        }
      }
    }
  }

  &.--with-icon {

    .choices {
      box-shadow: inset 0 0 0 1px $primary;
      border-radius: general.$border-base;
      background-color: colors.$white;

      &.is-open {
        border-radius: general.$border-base general.$border-base 0 0;
      }

      &.is-flipped {
        border-radius: 0 0 general.$border-base general.$border-base;

        & > .choices__list {

          &::before {
            border-radius: general.$border-base general.$border-base 0 0;
          }

          &::after {
            top: 100%;
          }
        }
      }
    }

    .choices__inner { /* stylelint-disable-line no-descending-specificity */
      background-color: transparent;
      padding: general.$spacing * 1.5 17px;
      padding-right: 55px;

      &::before { /* stylelint-disable-line no-descending-specificity */
        right: 20px;
      }

      .choices__item { /* stylelint-disable-line no-descending-specificity */
        text-align: left;
        padding: 0;
        line-height: 32px;
        font-size: 26px;
        display: flex;
        align-items: center;
        gap: 0 17px;
        min-height: 40px;
      }
    }

    .choices__inner + .choices__list { /* stylelint-disable-line no-descending-specificity */
      padding: general.$spacing * 0.5 0 !important;
      padding-bottom: general.$spacing * 1.5 !important;
      max-height: 3555px !important;

      .choices__list {
        max-height: 336px !important;
      }

      &::before { /* stylelint-disable-line no-descending-specificity */
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-shadow: inset 0 0 0 1px $primary;
        background-color: colors.$white;
        border-radius: 0 0 general.$border-base general.$border-base;
      }

      &::after { /* stylelint-disable-line no-descending-specificity */
        content: "";
        position: absolute;
        top: 0;
        left: 1px;
        width: calc(100% - 2px);
        height: 2px;
        background-color: colors.$white;
        transform: translateY(-50%);
      }

      .choices__item { /* stylelint-disable-line no-descending-specificity */
        padding: general.$spacing 17px;
        line-height: 32px;
        font-size: 26px;
        display: flex;
        gap: 0 17px;
        width: calc(100% - 2px);
        margin-left: 1px;

        img {
          width: 32px;
          max-height: 32px;
        }

        &:hover,
        &:active,
        &:focus,
        &.is-highlighted {
          background-color: colors.$gray-100;
          border-radius: general.$border-base;
        }
      }
    }
  }

  &.--outline-white {

    .choices {
      background-color: transparent;
      box-shadow: inset 0 0 0 1px colors.$gray-100;
      color: colors.$gray-100;
      border-radius: general.$border-base;

      &.is-open {
        border-radius: general.$border-base general.$border-base 0 0;
      }

      &.is-flipped {
        border-radius: 0 0 general.$border-base general.$border-base;

        & > .choices__list {

          &::before {
            border-radius: general.$border-base general.$border-base 0 0;
          }

          &::after {
            top: 100%;
          }
        }
      }
    }

    .choices__inner { /* stylelint-disable-line no-descending-specificity */
      background-color: transparent;
    }

    .choices__list--dropdown {
      background-color: colors.$black;
      box-shadow: inset 0 0 0 1px colors.$gray-100;

      &.is-active {
        border-radius: 0 0 general.$border-base general.$border-base;
      }

      .choices__item--selectable.is-highlighted {
        background: linear-gradient(colors.$gray-700, colors.$gray-700);
        background-size: calc(100% - 2px) 100%;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }
}

/* purgecss end ignore */
