@use "./../../scss/settings/s-colors.scss" as colors;

/* purgecss start ignore */

[data-autocomplete] + ul {
  // display: block !important;
  position: absolute;
  top: 100%;
  width: 100%;
  background-color: #fff;
  border-radius: 0 0 8px 8px;
  box-shadow: colors.$shadow-01;
  z-index: 5;
}

[data-autocomplete] + ul > li {
  color: colors.$gray;
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  font-weight: 700;
  cursor: pointer;

  &:hover {
    background-color: colors.$gray-100;
  }
}

.search-autocomplete__label,
.search-autocomplete__value {
  font-weight: normal;
  font-size: 14px;
  display: inline-block;
  margin-right: 8px;
  color: colors.$gray-300;
}

.search-autocomplete__arrow-icon,
.search-autocomplete__area-icon {
  height: 24px;
  width: 24px;
}

.search-autocomplete__area-icon {
  margin-right: 8px;
}

.search-autocomplete__geolocation {
  display: flex;
  padding: 16px;
  text-decoration: none;
  width: 100%;
  cursor: pointer;

  &:hover {
    background-color: colors.$gray-100;
  }
}

// .search-autocomplete__arrow-icon {
// }

.search-autocomplete__geolocation-main {
  color: colors.$primary;
  margin-right: 16px;
}

.search-autocomplete__geolocation-note {
  font-size: 14px;
  font-style: italic;
  color: colors.$gray-300;
  flex-grow: 1;
}

/* purgecss end ignore */
