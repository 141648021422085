@use "settings/s-colors.scss" as colors;

/*! purgecss start ignore */

.map-layout__aside {
  overflow-y: scroll;
  box-shadow: 0 3px 6px #00000029;
  position: relative;

  @include media-breakpoint-down(lg) {
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(100%);
    background-color: colors.$white;
    transition: transform 0.3s ease;
    width: 488px;

    &.--open {
      transform: translateX(0);
    }
  }

  @include media-breakpoint-down(md) {
    max-width: 376px;
    width: 100%;
  }
}

.map-layout__filter {
  overflow-y: scroll;

  @include media-breakpoint-up(lg) {
    display: none;
  }

  @include media-breakpoint-down(lg) {
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(100%);
    background-color: colors.$white;
    transition: transform 0.3s ease;
    width: 488px;
  }

  @include media-breakpoint-down(md) {
    max-width: 376px;
    width: 100%;
  }
}

.map-layout {
  display: grid;
  grid-template-columns: 1fr 488px;
  transition: grid-template-columns 0.3s ease;

  @include media-breakpoint-down(lg) {
    display: block;
    position: relative;
    overflow: hidden;
  }

  &.--compact {

    @include media-breakpoint-up(lg) {
      grid-template-columns: 1fr 488px 376px;

      .map-layout__filter {
        display: block;
      }
    }

    @include media-breakpoint-down(lg) {

      .map-layout__filter {
        transform: translateX(0);
      }
    }
  }
}

.map-layout__aside-toggle {
  position: absolute;
  top: 8px;
  right: 0;
  background-color: colors.$white;
  padding: 16px;
  border-radius: 8px 0 0 8px;
  box-shadow: 0 3px 6px #00000029;
  cursor: pointer;
  font-size: 18px;
  color: colors.$primary;
  display: flex;
  gap: 8px;
  align-items: center;

  svg {
    height: 16px;
  }

  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.map-layout__map {
  position: relative;
}

/*! purgecss end ignore */
