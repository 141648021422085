@use "./../../../scss/settings/s-colors.scss" as colors;

/* purgecss start ignore */

.s-estate-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 50;
  transform: translateY(100%);
  transition: transform 0.3s ease;
  will-change: transform;

  &.--active {
    transform: translateY(0%);

    @include media-breakpoint-down(md) {
      transform: translateY(-48px);
    }
  }
}

.s-estate-bar__container {
  background-color: colors.$gray-100;
  padding: 24px;
  border-radius: 8px 8px 0 0;

  @include media-breakpoint-down(md) {
    padding: 16px;
  }
}

.s-estate-bar__flow {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}

.s-estate-bar__buttons {
  display: flex;
  gap: 24px;
  align-items: center;

  @include media-breakpoint-down(md) {
    justify-content: space-between;
    width: 100%;
  }
}

.s-estate-bar__socials {
  display: flex;
  gap: 8px;
}

.s-estate-bar__heading {
  font-size: 21px;
  font-weight: bold;
}

.s-estate-bar__person {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.s-estate-bar__person-name {
  font-size: 21px;
}

.s-estate-bar__content {
  display: flex;
  column-gap: 56px;
  row-gap: 24px;
  align-items: center;

  @include media-breakpoint-down(md) {
    display: none;
  }
}

.s-estate-bar__person-logo {

  @include media-breakpoint-down(lg) {
    display: none;
  }
}

.s-estate-bar__button {
  position: relative;
}

.s-estate-bar__button-status {
  z-index: 2;
  position: absolute;
  left: 50%;
  bottom: 0;
  pointer-events: none;
  transform: translateX(-50%) translateY(20px);
}

/* purgecss end ignore */
