@use "./../../../scss/settings/s-colors.scss" as colors;

.i-statistical {
  display: flex;
  gap: 32px;
}

.i-statistical__title {
  width: 120px;
  flex-shrink: 0;
  font-weight: 400;
}

.i-statistical__value {

  .link__title-wrap {
    white-space: normal;
  }
}
