@use "./../../../scss/settings/s-colors.scss" as colors;

.i-info {
    display: flex;
    gap: 32px;

    @include media-breakpoint-down(md) {
        flex-wrap: wrap;
        gap: 0;
    }
}

.i-info__title {
    width: 200px;
    flex-shrink: 0;
    font-weight: 500;

    @include media-breakpoint-down(xxl) {
        width: 160px;
    }

    @include media-breakpoint-down(xl) {
        width: 120px;
    }

    @include media-breakpoint-down(md) {
        width: 100%;
    }
}

.i-info__value {
    color: colors.$gray;
    max-width: 142px;
}
