@use "./../../scss/settings/s-colors.scss" as colors;

@use "./../../scss/settings/s-general.scss" as general;

.footer-column {
  position: relative;
  padding: general.$spacing * 3;
  padding-bottom: 0;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 1px;
    background-color: colors.$gray-300;
  }

  @include media-breakpoint-down(md) {
    padding: general.$spacing 19px;
    padding-bottom: 0;
  }
}

.footer-column__heading {
  margin-bottom: general.$spacing * 5;
  color: colors.$gray-100;
}

.footer-column__perex {
  color: colors.$gray-200;
  margin-bottom: general.$spacing * 7;

  @include media-breakpoint-down(md) {
    margin-bottom: general.$spacing * 8;
  }
}

.footer-column__subheading {
  margin-bottom: general.$spacing * 3;
  color: colors.$gray-100;
}
