.f-search {
  display: flex;
  position: relative;

  .f-wrap {
    flex-grow: 1;
    height: 100%;
    margin-right: -12px;
    width: calc(100% - 12px);
  }

  .f-base {
    height: 48px;
    display: flex;
    align-items: center;
  }
}

.f-search__button {
  flex-shrink: 0;
  height: 100%;

  @include media-breakpoint-down(sm) {

    .btn {
      width: 48px;
      height: 48px;
    }

    .btn__title {
      display: none;
    }
  }
}
