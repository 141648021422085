@use "settings/s-general.scss" as general;

@use "settings/s-colors.scss" as colors;

$paddingX: 16px;
$paddingY: 7px;
$borderRadius: general.$border-base;
$fontSize: 15px;
$background: colors.$white;
$color: colors.$black;
$colorPlaceholder: colors.$gray-300;
$colorInvalid: colors.$danger;
$borderColor: colors.$gray-200;
$borderColorFocus: colors.$primary-200;
$textAreaMinHeight: 115px;

.f-base {
  display: block;
  width: 100%;
  color: $color;
  background-color: $background;
  background-clip: padding-box;
  padding: $paddingY $paddingX;
  border-radius: $borderRadius;
  font-size: $fontSize;
  appearance: none;
  border: 1px solid $borderColor;
  transition: border-color 0.3s ease;

  &:focus {
    color: $color;
    border-color: $borderColorFocus;
    outline: none;
  }

  &::placeholder {
    opacity: 1;
    color: $colorPlaceholder;
  }

  &.--invalid {
    border-color: $colorInvalid;
  }

  &.--background-gray-100 {
    background-color: colors.$gray-100;
  }
}

textarea.f-base {
  min-height: $textAreaMinHeight;
  resize: none;
}
