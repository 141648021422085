/* purgecss start ignore */

.s-agents-slider__bottom-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
}

.s-agents-slider__search {
  max-width: 440px;
  margin-bottom: 64px;
}

.s-agents-slider {
  overflow: hidden;

  .base-header {
    margin-bottom: 0;
  }

  .i-agent {
    width: min(100%);

    @include media-breakpoint-up(md) {
      width: 487px;
    }
  }

  .splide .splide__slide {

    @include media-breakpoint-down(md) {
      width: 100% !important;
    }
  }
}

/* purgecss end ignore */
