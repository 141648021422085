@use "./../../../scss/settings/s-colors.scss" as colors;

.i-driving-distance {
  display: flex;
  flex-direction: column;
  gap: 64px;

  @include media-breakpoint-down(md) {
    gap: 40px;
  }
}

.i-driving-distance__inputs {
  display: flex;
  width: 100%;
  align-items: flex-end;
  gap: 16px;

  @include media-breakpoint-down(md) {
    flex-direction: column;
  }
}

.i-driving-distance__input {
  flex-grow: 1;
  width: 100%;
}

.i-driving-distance__arrow {
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding: 16px;
  width: 60%;

  @include media-breakpoint-down(md) {
    display: none;
  }
}

.i-driving-distance__arrow-line {
  display: block;
  width: 100%;
  height: 1px;
  border-bottom: 5px dotted colors.$primary-200;
}

.i-driving-distance__results {
  display: flex;
  gap: 16px;
  justify-content: space-between;
  padding: 0 40px;

  @include media-breakpoint-down(md) {
    flex-direction: column;
    padding: 0;
  }
}

.i-driving-distance__result-item {
  display: flex;
  align-items: center;
  gap: 16px;
}

.i-driving-distance__result-icon {
  width: 40px;
  flex-shrink: 0;
}

.i-driving-distance__result-text {
  color: colors.$gray-300;
  font-style: italic;
}
