@use "settings/s-colors.scss" as colors;

/*! purgecss start ignore */

.main-inline-map {
  position: relative;
  width: 100%;
  height: 570px;
  border-radius: 8px;

  .gm-style-iw-d,
  .gm-style-iw {
    padding: 0 !important;
    overflow: hidden !important;
  }
}

.main-inline-map__iw {
  width: 370px;
  max-width: 100%;
  display: flex;

  a,
  span {
    display: block;
    font-size: 16px;
  }
}

.main-inline-map__iw-content {
  padding: 16px;
}

.main-inline-map__city {
  font-weight: 700;
  font-size: 18px;
}

.main-inline-map__type {
  color: colors.$gray-300;
  font-style: italic;
}

.main-inline-map__address {
  line-height: 1.6;
  margin: 8px 0;
}

.main-inline-map__link {
  text-decoration: none;
}

.main-inline-map__iw-image {
  width: 176px;
}

/*! purgecss end ignore */
