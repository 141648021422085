/* purgecss start ignore */

.js-toggle-show {
  display: block;

  &[data-toggle-style="flex"] {
    display: flex;
  }

  &[data-toggle-style="inline"] {
    display: inline;
  }
}

.js-toggle-hiden {
  display: none;
}

/* purgecss end ignore */
