@use "./../../../scss/settings/s-colors.scss" as colors;

.s-map {

  .f-search {
    width: 576px;
    max-width: 100%;
    margin: 0 auto 64px;

    .btn {
      height: 100%;
    }

    @include media-breakpoint-down(md) {
      margin: 0 auto 46px;
    }
  }

  .f-base {
    background-color: colors.$white;
  }

  .map-image {
    max-width: 996px;
    margin-inline: auto;

    svg {
      margin: 0 auto;
      display: block;
      max-width: 100%;
    }
  }

  #Component_133_1,
  #Component_134_1,
  #Component_132_1,
  #Component_131_1,
  #Component_130_1,
  #Component_129_1,
  #Component_128_1 {
    cursor: pointer;

    #Group_1002,
    #Group_1001,
    #Group_1000,
    #Group_999,
    #Group_997,
    #Group_998,
    #Group_996 {
      transition: transform 0.2s ease-in-out;
    }

    #Path_1883,
    #Path_1884,
    #Path_1882,
    #Path_1878,
    #Path_1879,
    #Path_1880,
    #Path_1881 {
      transition: fill 0.2s ease-in-out;
    }

    &:hover {

      #Path_1883,
      #Path_1884,
      #Path_1882,
      #Path_1878,
      #Path_1879,
      #Path_1880,
      #Path_1881 {
        opacity: 1;
        fill: colors.$conversion;
      }

      #Group_1002 {
        transform: translate(95.86px, 111.39px);
      }

      #Group_1001 {
        transform: translate(163.376px, 71.1px);
      }

      #Group_1000 {
        transform: translate(107.531px, 110.72px);
      }

      #Group_999 {
        transform: translate(134.218px, 68.034px);
      }

      #Group_998 {
        transform: translate(122.52px, 78.525px);
      }

      #Group_997 {
        transform: translate(111.458px, 119.412px);
      }

      #Group_996 {
        transform: translate(114.77px, 66.19px);
      }
    }
  }
}
