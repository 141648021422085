@use "./../../../scss/settings/s-general.scss" as general;

@use "./../../../scss/settings/s-colors.scss" as colors;

.s-estate-detail-intro__top {
  position: relative;
}

.s-estate-detail-intro__arrows {
  height: 0;
  width: 100%;

  .swiper-arrows {
    position: static;

    &.--lg .swiper-arrows__arrow {
      position: absolute;
      top: 50%;
      transform: translate(0%, -50%);
    }

    .swiper-button-prev {
      left: 24px;
    }

    .swiper-button-next {
      right: 24px;
    }
  }
}

.s-estate-detail-intro__top-content-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  position: relative;
  pointer-events: none;

  @include media-breakpoint-up(xl) {
    background: linear-gradient(180deg, #2e3a4300 50%, #2e3a4384 75%, #2e3a43 100%);
    aspect-ratio: 3/2;
    z-index: 2;
  }

  @include media-breakpoint-down(xl) {
    position: static;
  }
}

.s-estate-detail-intro__top-content {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 8px;
  z-index: 5;

  @include media-breakpoint-up(xl) {
    pointer-events: none;
    color: colors.$white;
  }
}

.s-estate-detail-intro__energy-label {
  margin-left: 24px;
}

.s-estate-detail-intro__badges {
  margin-left: 24px;

  .g-badges {

    &.--horizontal {

      @include media-breakpoint-down(xl) {
        position: absolute;
        top: 16px;
        flex-direction: column;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    margin-left: 0;

    .g-badges {
      gap: 4px;
    }
  }
}

.s-estate-detail-intro__status {
  background-color: colors.$primary;
  border-radius: 0 29px 29px 0;
  padding: general.$spacing * 2 20px;
  padding-right: 46px;
  display: inline-block;
  width: auto;
  color: colors.$white;

  @include media-breakpoint-down(xl) {
    margin-top: general.$spacing * -9;
    padding: general.$spacing * 1.5 20px;
    margin-left: 16px;
    border-radius: 29px;
  }

  @include media-breakpoint-down(sm) {
    margin-left: 0;
  }
}

.s-estate-detail-intro__header {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-left: 12px;
  padding-bottom: general.$spacing * 2;
  padding-top: general.$spacing * 3;

  @include media-breakpoint-up(xl) {
    border-left: 8px solid colors.$primary;
  }

  @include media-breakpoint-down(sm) {
    padding-left: 0;
    padding-bottom: 0;
  }
}

.s-estate-detail-intro__heading {

  &.base-h1 {
    font-weight: 400;
  }

  @include media-breakpoint-down(sm) {

    &.base-h1 {
      font-size: 26px;
      line-height: 32px;
    }
  }
}

.s-estate-detail-intro__perex {
  margin-bottom: 0;

  &.base-h2 {
    font-weight: 400;
  }

  @include media-breakpoint-down(sm) {

    &.base-h2 {
      font-size: 18px;
      line-height: 24px;
    }
  }
}

.s-estate-detail-intro__overview {
  position: absolute;
  bottom: 16px;
  right: 16px;
  z-index: 15;
  display: block;
}

.s-estate-detail-intro__slider {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;

  @include media-breakpoint-down(xl) {
    position: relative;
  }

  @include media-breakpoint-down(sm) {
    width: calc(100% + 32px);
    margin-left: -16px;
    border-radius: 0;
  }
}

.s-estate-detail-intro__info {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: general.$spacing * 3 20px;
  gap: 40px;

  @include media-breakpoint-down(sm) {
    padding-left: 0;
    padding-right: 16px;
    padding-bottom: 0;
    padding-top: general.$spacing;
    gap: 18px;
  }
}

.s-estate-detail-intro__price {
  white-space: nowrap;

  &.base-h1 {
    font-weight: bold;
  }

  @include media-breakpoint-down(xl) {

    &.base-h1 {
      font-size: 26px;
      line-height: 32px;
    }
  }
}

.s-estate-detail-intro__footer {
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;

  @include media-breakpoint-down(sm) {
    display: none;
  }

  .btn {
    flex-shrink: 0;
  }
}

.s-estate-detail-intro__socials {
  display: flex;
  gap: 8px;

  @include media-breakpoint-up(xl) {
    margin-left: auto;
  }

  .g-share-buttons {
    opacity: 1;
    pointer-events: all;
  }
}

.s-estate-detail-intro__buttons {
  display: flex;
  gap: 8px;
}

.s-estate-detail-intro__info-block {
  display: flex;
  align-items: center;
  gap: 18px;
}

.s-estate-detail-intro__info-list {
  display: flex;
  align-items: center;

  li {
    display: inline-block;
    padding: 8px 18px;
    border-left: 1px solid colors.$gray;
  }
}

.s-estate-detail-intro__button-perex {
  flex-shrink: 1;
  display: flex;
  align-items: center;
  gap: 18px;

  p {
    margin-bottom: 0;
    max-width: 280px;
  }

  @include media-breakpoint-down(xxl) {
    display: none;
  }
}

.s-estate-detail-intro__cta-button-tooltip {
  display: none;
  color: colors.$white;
  padding-left: 8px;
  font-size: 18px;
}

.s-estate-detail-intro__cta-button {
  display: flex;
  align-items: center;
}

.s-estate-detail-intro__button-primary {
  position: relative;
}

.s-estate-detail-intro__button-status {
  z-index: 2;
  position: absolute;
  left: 50%;
  bottom: 0;
  pointer-events: none;
  transform: translateX(-50%) translateY(20px);
}
