@use "settings/s-colors.scss" as colors;

/*! purgecss start ignore */

.main-map-aside {

  .f-option-switch {
    margin-bottom: 0;

    .f-option-switch__button {

      @include media-breakpoint-down(md) {
        padding-left: 16px;
        padding-right: 16px;
      }
    }
  }

  .choices__list--dropdown {
    right: 0;
  }
}

.main-map-aside__body,
.main-map-aside__header {
  padding-left: 16px;
  padding-right: 16px;
}

.main-map-aside__list-header {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  justify-content: space-between;
  position: relative;
  flex-wrap: wrap;

  @include media-breakpoint-up(md) {

    br {
      display: none;
    }
  }

  &::after {
    content: "";
    position: absolute;
    display: block;
    left: 50%;
    bottom: -12px;
    transform: translateX(-50%);
    width: 100%;
    height: 1px;
    background-color: colors.$gray-200;
  }
}

.main-map-aside__pagination {
  position: sticky;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 -3px 6px #00000029;
  background-color: colors.$gray-100;
  z-index: 5;
  padding: 10px 16px;

  @include media-breakpoint-down(lg) {
    display: none;
  }
}

.main-map-aside__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  margin-top: 8px;
  flex-wrap: wrap;
  row-gap: 8px;

  @include media-breakpoint-down(lg) {
    justify-content: flex-end;
    column-gap: 16px;
  }
}

.main-map-aside__close {
  margin-right: auto;

  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.main-map-aside__close-button {
  display: inline-flex;
  gap: 8px;
  align-items: center;
  color: colors.$primary;
  cursor: pointer;
  font-size: 18px;

  svg {
    height: 16px;
    order: -1;
  }
}

.main-map-aside__count {
  line-height: 20px;

  @include media-breakpoint-down(lg) {
    text-align: right;
    display: flex;
    align-items: center;
  }
}

/*! purgecss end ignore */
