%base-h1 {
  font-size: 42px;
  line-height: 48px;
  font-weight: 400;
}

%base-h2 {
  font-size: 26px;
  line-height: 32px;
  font-weight: bold;
}

%base-h3 {
  font-size: 21px;
  line-height: 24px;
}

%base-h4 {
  font-size: 18px;
  line-height: 24px;
}

.base-h1 {

  @extend %base-h1;
}

.base-h2 {

  @extend %base-h2;
}

.base-h3 {

  @extend %base-h3;
}

.base-h4 {

  @extend %base-h4;
}
