@use "./../../../scss/settings/s-colors.scss" as colors;

/* purgecss start ignore */

.i-star {

  svg {

    .half-star {
      opacity: 0;
    }
  }

  &.--full {

    svg {

      g {
        fill: colors.$conversion;
      }

      .half-star {
        opacity: 0;
      }
    }
  }

  &.--half {

    svg {

      .half-star {
        opacity: 1;
      }
    }
  }
}

/* purgecss end ignore */
