@use "./../../../scss/settings/s-colors.scss" as colors;

.i-real-estate-agency__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;

  @include media-breakpoint-down(lg) {
    flex-wrap: wrap;
  }
}

.i-real-estate-agency__image,
.i-real-estate-agency__statistical-group,
.i-real-estate-agency__description {
  width: 33.333%;

  @include media-breakpoint-down(lg) {
    width: 100%;
  }
}

.i-real-estate-agency__image {
  display: flex;
  align-items: flex-start;
  justify-content: center;

  @include media-breakpoint-down(lg) {
    height: 148px;
    align-items: center;
  }

  img {
    margin: 0 auto;
  }
}

.i-real-estate-agency {
  padding: 16px 24px 32px;
  box-shadow: 0 4px 16px colors.$gray-200;
  border-radius: 10px;

  @include media-breakpoint-down(lg) {
    padding: 16px;
  }
}

.i-real-estate-agency__title {
  margin-bottom: 8px;

  a {
    font-size: inherit;
  }
}

.i-real-estate-agency__rating {
  margin-bottom: 24px;
}

.i-real-estate-agency__description {
  margin-top: auto;

  @include media-breakpoint-down(lg) {
    padding-top: 0;
  }
}

.i-real-estate-agency__perex {
  margin-bottom: 24px;
  line-height: 1.4;

  &.--mobile {
    display: none;
  }

  @include media-breakpoint-down(lg) {
    display: none;

    &.--mobile {
      display: block;
    }
  }
}
