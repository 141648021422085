@use "./../../scss/settings/s-colors.scss" as colors;

.user-navigation-bar {
  position: fixed;
  display: flex;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: colors.$gray-100;
  z-index: 100;
  padding: 16px 0;

  @include media-breakpoint-up(md) {
    display: none;
  }
}

.user-navigation-bar__container {

  @include media-breakpoint-down(sm) {
    padding: 0 32px;
  }
}

.user-navigation-bar__list {
  display: flex;
  gap: 8px;
  justify-content: space-between;
}
