@use "./../../../scss/settings/s-colors.scss" as colors;

.s-real-estate-agency {

  .base-header {
    max-width: 100%;
    margin-bottom: 24px;
  }

  .f-base {
    font-style: italic;
  }

  .f-search {
    width: 550px;
    max-width: 100%;

    @include media-breakpoint-down(lg) {
      margin-bottom: 24px;

      .btn {
        width: auto;
      }

      .btn__title {
        display: table;
        transform: translateX(0);
      }
    }
  }

  .s-real-estate-agency__button {
    background-color: colors.$gray-100;

    path {
      fill: none;
    }

    @include media-breakpoint-down(md) {
      margin: 0 auto;
    }
  }
}

.s-real-estate-agency__wrapper {
  display: flex;
  margin-bottom: 72px;

  @include media-breakpoint-down(lg) {
    flex-wrap: wrap;
    margin-bottom: 24px;
  }
}
