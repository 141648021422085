$primary-100: #d9e9f5;
$primary-150: #dce5ed;
$primary-200: #6c98b9;
$primary-300: #366586;
$primary: #0062ab;
$primary-600: #054b80;
$primary-800: #062841;

$conversion: #f5ac21;
$conversion-600: #f2982e;
$conversion-gradient: linear-gradient(180deg, $conversion 0%, $conversion-600 100%);

$success: #79c431;

$danger: #fd3e3e;
$danger-600: #d81056;

$info: #1fc7ce;

$black: #2e3a43;

$white: #fff;

$gray-700: #212529;
$gray-600: #2e3a43;
$gray: #536069;
$gray-300: #898f93;
$gray-200: #cad0d6;
$gray-150: #dce5ed;
$gray-100: #f4f7fa;
$gray-50: #fcfdfe;

$shadow: inset 0 1px 2px $white, 0 9px 34px #2e3a4334;
$shadow-01: 0 4px 16px #cad0d667;
$shadow-02: 0 3px 6px #00000019;
$shadow-03: 0 4px 16px #00000029;
$shadow-04: 0 4px 16px #cad0d6cc;

//Badge colors

$magenta: #d81056;
$turquoise: #1fc7ce;
$green: $success;
$yellow: #d8a704;
$light-blue: #049fd8;
$red: #fd3e3e;
$dark-blue: $primary;

//Socials

$whatsapp: #07d658;
$messenger: linear-gradient(45deg, rgb(67 149 250 / 100%) 0%, rgb(224 62 253 / 100%) 100%);
$facebook: #4395ff;
$twitter: #1c9bea;

// Reality
$flat: #5caae6;
$house: #6cd6b1;
$land: #e5e05b;
$commercial: #eba661;
$other-reality: #eb619b;

//Energy labels

$extremly_eco: #79c431;
$very_eco: #9dc934;
$eco: #c2cf38;
$less_eco: #e4d33b;
$uneco: #e2902b;
$very_uneco: #e0471e;
$extremly_uneco: #de0819;

//Map homepage

$map-base: #f4f5fd;
