@use "./../../scss/settings/s-colors.scss" as colors;

@use "./../../scss/settings/s-general.scss" as general;

.side-menu__wrapper {
  background-color: colors.$gray-100;
  padding: 16px;
  border-radius: 10px;

  &.--transparent {
    background-color: transparent;
  }

  .base-header__heading-wrapper {
    font-size: 18px;
    padding-bottom: 0;
    border-bottom: 1px solid colors.$primary-100;
  }

  .base-header {
    margin-bottom: 0;
  }

  @include media-breakpoint-down(xl) {
    display: none;
  }

  @include media-breakpoint-up(xl) {
    display: block !important;
  }
}
