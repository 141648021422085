@use "./../../scss/settings/s-colors.scss" as colors;

@use "./../../scss/settings/s-general.scss" as general;

.cta-button {
  border: none;
  display: block;
  height: general.$spacing * 6;
  width: general.$spacing * 6;
  box-shadow: colors.$shadow-02;
  border-radius: 50%;
  background-color: colors.$white;
  position: relative;

  path,
  circle {
    transition: fill 0.2s, stroke 0.2s;
  }

  .tooltip {
    position: absolute;
    opacity: 0;
    transition: opacity 0.2s;
    top: -2px;
    left: 50%;
    transform: translate(-50%, -100%);
  }

  &:hover {
    cursor: pointer;

    path,
    circle {
      stroke: colors.$conversion;
    }

    .tooltip {
      opacity: 1;
      pointer-events: inherit;
    }
  }

  &.--active {

    path,
    circle {
      fill: colors.$conversion;
      stroke: colors.$conversion;
    }

    .tooltip {
      opacity: 0;
    }
  }
}

.cta-button__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  pointer-events: none;
}
