@use "./../../../scss/settings/s-colors.scss" as colors;

.s-estate-pagination__arrow-icon {
  max-width: 68px;
  flex: 0 0 68px;
  height: 68px;
  background-color: colors.$gray-100;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: colors.$shadow-01;

  @include media-breakpoint-down(sm) {
    max-width: 48px;
    flex: 0 0 48px;
    height: 48px;

    .icon {
      flex: 0 0 16px;
      max-width: 16px;
      height: 16px;
    }
  }
}

.s-estate-pagination__arrow-label {
  color: colors.$primary;
  font-size: 18px;
  line-height: 20px;
}

.s-estate-pagination__back-link {
  display: block;
  margin-left: auto;
  margin-right: auto;

  @include media-breakpoint-down(sm) {
    order: 1;
    width: 100%;
    text-align: center;
  }
}

.s-estate-pagination__flow {
  display: flex;
  align-items: center;

  @include media-breakpoint-up(xl) {
    margin-right: 56px;
    margin-left: 56px;
  }

  @include media-breakpoint-down(sm) {
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 40px;
  }
}

.s-estate-pagination__arrow {
  display: flex;
  align-items: center;
  max-width: 182px;
  column-gap: 16px;
  row-gap: 8px;

  &.--next {
    text-align: right;

    .s-estate-pagination__arrow-label {
      order: -1;
    }
  }
}

.s-estate-pagination {

  a {
    text-decoration-color: transparent;

    &:hover {
      text-decoration-color: colors.$primary;
    }
  }
}
