@use "./../../../scss/settings/s-colors.scss" as colors;

@use "./../../../scss/settings/s-general.scss" as general;

.i-category-card__body {
  padding: 16px 32px;
  background-color: colors.$white;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% + 3px); // Image overflow fix
  border-radius: general.$border-base;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.i-category-card__count {
  font-size: 18px;

  @include media-breakpoint-down(xl) {
    font-size: 16px;
  }
}

.i-category-card__header {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  column-gap: 8px;

  @include media-breakpoint-down(md) {
    flex-direction: column;
  }
}

.i-category-card__heading {
  font-size: 42px;

  @include media-breakpoint-down(xl) {
    font-size: 21px;
  }
}

.i-category-card__heading-link {
  color: colors.$black;
  text-decoration-color: transparent;
  display: block;

  &:hover {
    color: colors.$black;
    text-decoration-color: colors.$black;
  }
}

.i-category-card__image {
  display: block;
}

.i-category-card__image-link {
  display: block;
  border-radius: general.$border-base;
  overflow: hidden;
}

.i-category-card {
  position: relative;

  @include media-breakpoint-up(xl) {
    height: 100%;
  }

  &.--compact {

    .i-category-card__body {
      padding: 12px 24px;
      position: relative;
      margin-top: -18px;
      z-index: 3;
    }

    .i-category-card__heading {
      font-size: 21px;
    }

    .i-category-card__count {
      font-size: 16px;
    }
  }
}
