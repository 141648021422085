@use "./../../../scss/settings/s-colors.scss" as colors;

@use "./../../../scss/settings/s-general.scss" as general;

$borderRadius: general.$border-base;
$borderColor: colors.$gray-200;
$paddingX: 16px;
$paddingY: 13px;

.f-base {

  &.--highlited {
    border: 2px solid colors.$conversion;
  }
}

.input__wrapper {
  display: flex;
  align-items: center;
  border-radius: $borderRadius;
  border: 1px solid $borderColor;

  .f-base {
    border: none;
    padding: 0;
  }

  .--highlited {
    border: 2px solid colors.$conversion;
  }

  .input__icon {
    padding-right: 16px;
  }
}
